import React from 'react';

const Footer = () => (
  <footer className="text-gray-800 bg-blue-200">
    <div className="container mx-auto py-10 flex justify-start">
      <div className="px-3">
        <h2 className="text-lg font-semibold">Dedalog Serviços de Logística Ltda.</h2>
        <p className="mt-5">CNPJ: 39.574.088/0001-90</p>
        <p>
          <a href="http://www.freepik.com">Hero image Designed by macrovector / Freepik</a>
        </p>
      </div>
      <div className="px-24">
        <h2 className="text-lg font-semibold">Redes Sociais</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="https://www.linkedin.com/company/42447343/">Linkedin</a>
          </li>
          <li>
            <a href="https://github.com/Dedalog/">GitHub</a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;

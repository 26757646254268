import React from 'react';
import ChatwootWidget from '../chatwoot/ChatwootWidget';
import Footer from './Footer';
import Header from './Header';
import { Helmet } from 'react-helmet';

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Logística completa para e-commerce | Dedalog Logística</title>
        <meta
          name="Description"
          CONTENT="Centros de logística urbanos inteligentes para armazenar, empacotar e enviar os pedidos do seu e-commerce em poucos minutos."
        />
        <link rel="canonical" href="https://dedalog.com.br/" />
      </Helmet>
      <Header />
      <main className="text-gray-900">{children}</main>
      <Footer />
      <ChatwootWidget />
    </>
  );
};

export default Layout;

import React from 'react';
import Button from '../components/Button';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const ButtonContact = () => {
  return (
    <AnchorLink className="text-white font-bold" title="Falar com especialista" to="/contato">
      <Button>Falar com especialista</Button>
    </AnchorLink>
  );
};

export default ButtonContact;

import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { StaticImage } from 'gatsby-plugin-image';
import ButtonContact from '../ButtonContact';

const Header = () => (
  <header id="header" className="sticky top-0">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex text-white items-center text-2xl">
        <div className="w-12 mr-3">
          <StaticImage
            src="../../assets/images/dedalog-logo-transparente.png"
            layout="constrained"
            placeholder="tracedSVG"
            alt="Dedalog Logo Transparente"
          />
        </div>
        Dedalog
      </div>
      <div className="flex mt-4 sm:mt-0">
        <AnchorLink
          className="px-4 text-white font-bold"
          title="Nossos serviços"
          to="/#servicos"
          stripHash
        >
          Serviços
        </AnchorLink>
        <AnchorLink
          className="px-4 text-white font-bold"
          title="Nossa tecnologia"
          to="/#tecnologia"
          stripHash
        >
          Tecnologia
        </AnchorLink>
        <AnchorLink
          className="px-4 text-white font-bold"
          title="Preços da Dedalog"
          to="/precos"
          stripHash
        >
          Preços
        </AnchorLink>
        <AnchorLink className="px-4 text-white font-bold" title="Blog da Dedalog" to="/blog">
          Blog
        </AnchorLink>
      </div>
      <div className="hidden md:block">
        <ButtonContact />
      </div>
    </div>
  </header>
);

export default Header;
